
<template>
  <div fluid class="page-container">
    <!-- Fondo azul en la parte superior -->
    <div class="header-bg">
      <v-row justify="center" class="" >
        <v-col cols="12" md="2"     style="padding: 0px; margin-top: 40px; margin-left: auto; margin-right: auto;
           display: flex; justify-content: center; align-items: center;"

   >
          <v-img   src="/img/acara_logo_3.png" contain width="200" height="200" ></v-img>
        </v-col>
      </v-row>
    </div>

<br>
<br>
<br>
<br>
    <div class="content-container">
      <!-- Logo -->


      <!-- Título -->
      <v-row  class="">
        <v-col cols="12" class="text-center ">
          <h1 class="title "><b>{{ title }}</b></h1>
        </v-col>
      </v-row>

      <!-- Alerta -->
      <v-row justify="center"  class="" v-if="alertText !=''">
        <v-col cols="12" md="6"  class="text-center " style="margin-left: 25%;">
          <v-alert class="alert-box "  :style="{backgroundColor: chipButton+'40'}">
            <template v-slot:prepend>
              <v-icon  style="font-size: 30px;" :style="{color: chipButton}">mdi-information</v-icon>
            </template>
            <span class="alert-text" style="font-weight: 400;font-size: 15px;" :style="{color: chipButton}">{{ alertText }}</b></span>
          </v-alert>
        </v-col>
      </v-row>


      <v-row justify="center">
        <v-col cols="12" md="6" class="text-center" style="margin-left: 25%;">
          <p class="texto-formateado" v-html="subtitle"></p>

        </v-col>
      </v-row>


      <v-row justify="center">
        <v-col cols="12"  xs="12" class="text-center ">
          <v-chip   class="chip-box"  :style="{ border: '2px solid ' + chipButton+' !important' }">
              <v-btn style=" width: 10px;height: 10px;pointer-events: none;"
                icon
                x-small
                :style="{ backgroundColor: chipButton }"
              ></v-btn>
             {{chipText}} </v-chip>
        </v-col>
      </v-row>


      <v-row
        justify="center"
        align="center"
      >


        <v-col cols="12"  xs="12" class="d-flex justify-center" style="margin-left: 8px;">
            <v-btn class="btn btn-primary btn-sm"  @click="cerrarSesion()" >Volver</v-btn>
        </v-col>


      </v-row>

    </div>
  </div>
</template>

<script>

	import { mapActions, mapGetters } from "vuex";

export default {
		name: "solicitudUsuarioEstado",

		data: (vm) => ({
      title:"",
			alertText: "",
			message: "",
			subtitle: "",
      chipText:"",
      chipButton:""

		}),

		computed: {
      sapProperties() {
        return this.$store.state.user.sapProperties || {}; // Evita undefined
      }
    },

    watch: {
      sapProperties(newVal) {
        if (newVal.U_MensajeWeb) {
          console.log("Se cargaron los datos:", newVal);
        }
      }
    },

		created() {
      this.loadMessage();

		},

		methods: {
      ...mapActions({
				signOut: "logout",
			}),

			 loadMessage() {


        switch(this.$route.params.tipo)
        {
          case "1": //usuario bloqueado



            this.title="Su usuario está bloqueado";
            this.alertText=this.sapProperties.U_MensajeWeb;
            this.subtitle="Tu cuenta ha sido bloqueada. Esto puede deberse a intentos de acceso no autorizados, incumplimiento de normas o razones de seguridad. Si crees que esto es un error, por favor contacta con el soporte técnico";
            this.chipText="Bloqueado";
            this.chipButton = "#FB8C00";

          break;

          case "2": //sitio en mantenimiento
            this.title="Mantenimiento en Progreso";
            this.alertText="Estamos trabajando para mejorar la experiencia";
            this.subtitle="Hemos activado un mantenimiento temporal en la plataforma. Esto puede deberse a actualizaciones, ajustes en el sistema o tareas administrativas.";
            this.chipText="Sistema en mantenimiento";
            this.chipButton = "#0078D4";
          break;

           case "3": //usuario inactivo
             this.title="Su usuario está inactivo";
             this.alertText="Su usuario está inactivo";
            this.subtitle="Tu cuenta ha sido bloqueada. Esto puede deberse a intentos de acceso no autorizados, incumplimiento de normas o razones de seguridad. Si crees que esto es un error, por favor contacta con el soporte técnico";
            this.chipText="Inactivo";
            this.chipButton = "#FF5252";
          break;

          case "4": //sitio en mantenimiento -rol bloqueado
            this.title="Mantenimiento en Progreso";
            this.alertText="Estamos trabajando para mejorar la experiencia";
            this.subtitle=this.$store.state.user.roles.mensaje;
            this.chipText="Sistema en mantenimiento";
            this.chipButton = "#0078D4";
          break;
        }

			},

      async cerrarSesion(){
        await this.signOut();
        var logoutOptions = { redirectUri: window.location.origin };
        sessionStorage.clear();
        if(this.$keycloak)
        {
          this.$keycloak
            .logout(logoutOptions)
            .then((success) => {
              Swal.close();
              console.log("--> log: logout success ", success);
            })
            .catch((error) => {
              Swal.close();
              console.log("--> log: logout error ", error);
            });
        }
        else{
          window.location ="/";
        }
      },

		},
		mounted() {





		},
	};
  </script>

<style scoped>


.sombra{
  -webkit-box-shadow: 0px 10px 11px -1px #aaa;
-moz-box-shadow: 0px 10px 11px -1px #aaa;
box-shadow: 0px 10px 11px -1px #aaa;

}
/* Fondo azul en la parte superior */
.header-bg {
  width: 100%;
  height: 150px;
  background: rgba(229, 243, 255, 1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.row{
  margin-top:0px !important
}
.page-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.content-container {

  width: 100%;
  text-align: center;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.message {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}

.alert-box {
  font-weight: 400;
  padding: 5px;
}

.alert-text {
  font-weight: 400;
  padding:5px 5px !important;
}

.chip-box {
  font-size: 14px;
  padding: 8px 16px;
  font-weight: bold;

    background-color: #fff !important;

    color: #888;

}
.texto-formateado {
  white-space: pre-wrap; /* Respeta saltos de línea y tabulación */
  /*font-family: monospace;*/
}
</style>
